<template>
  <b-overlay
    :show="loading"
    rounded="sm"
  >
    <div
      class="file-input-component-wrap"
      :class="[{ banner: forBanner }, { 'user-settings': forUserSettings}]"
    >
      <div class="file-input-component">
        <p>{{ label }}</p>
        <div v-if="!forUserSettings" :class="mutablePreviewImage.length ? 'has-image' : ''">
          <img
            v-for="(item,index) in mutablePreviewImage"
            :key="index"
            class="file-input-component-image-preview w-full h-auto"
            :src="item"
            alt=""
          >
        </div>
        <div class="alert alert-danger" v-if="maxFilesError">You can select max 3 files</div>
        <input
          ref="fileInput"
          class="file-input"
          type="file"
          :accept="accept"
          :multiple="multiple"
          @input="pickFile"
        >
        
        <b-button
          v-if="!forBanner && !forUserSettings"
          variant="gray"
          @click="pickFile"
        >
          <BootstrapIcon
            icon="plus-lg"
            size="1x"
          />
          {{ $t('forms.fileAdd') }}
        </b-button>
        <div
          v-if="forUserSettings && !previewImage"
          class="file-input-component-avatar"
        >
          <feather-icon
            icon="CameraIcon"
            size="24"
          />
        </div>
        <div
          v-if="forUserSettings && previewImage"
          class="file-input-component-avatar"
        >
          <img
            v-for="(item,index) in mutablePreviewImage"
            :key="index"
            :src="item"
            alt=""
          >
        </div>
        <b-button
          v-if="forUserSettings"
          variant="primary"
          @click="pickFile"
        >
          <feather-icon
            icon="Edit2Icon"
            size="14"
          />
        </b-button>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {BButton, BOverlay} from 'bootstrap-vue-3'
import ResourceUtils from "@/mixins/ResourceUtils"

export default {
  components: {
    BButton,
    BOverlay
  },
  mixins: [ResourceUtils],
  props: {
    previewImage: {type: String, default: null},
    forBanner: {type: Boolean, default: false},
    multiple: {type: Boolean, default: false},
    label: {type: String, default: 'Add files'},
    accept: {type: String, default: 'image/*'},
    clearAfterUpload: {type: Boolean, default: false},
    forUserSettings: {type: Boolean, default: false},
    resource: {type: String, default: null},
    fileAttribute: {type: String, default: null},
  },
  data() {
    return {
      mutablePreviewImage: [],
      loading: false,
      maxFilesError: false
    }
  },
  created() {
    if (this.previewImage && this.accept === 'image/*') {
      this.mutablePreviewImage.push(this.previewImage)
    }
  },
  methods: {
    pickFile() {
      this.maxFilesError = false
      const input = this.$refs.fileInput
      const file = input.files
      const promises = []
      if(input.files.length > 3) {
        this.maxFilesError = true
        return false
      }
      if (file && file[0]) {
        this.loading = true
        for (let i = 0; i < file.length; i += 1) {
          const reader = new FileReader()
          reader.onload = e => {
            if (!this.multiple) {
              this.mutablePreviewImage = []
            }
            if (this.accept === 'image/*') {
              this.mutablePreviewImage.push(e.target.result)
            }
          }
          reader.readAsDataURL(file[i])
          this.$emit('input', file[i])
          promises.push(this.fileUpload(file[i]))
        }
        Promise.all(promises)
          .then(results => {
            if (this.multiple) {
              const files = []
              results.forEach(element => {
                files.push(element.data)
              })
              this.$emit('fileUploaded', files)
            }

            if (results.length) {
              this.loading = false
            }
          })
      }
    },
    fileUpload(file) {
      const formdata = new FormData()
      formdata.append('file', file)
      if (this.resource !== null) {
        formdata.append('resource', this.resource)
      }
      if (this.fileAttribute !== null) {
        formdata.append('fileAttribute', this.fileAttribute)
      }

      return this.create(this.$Files, formdata, this.$t('messages.fileCreated'), null, this.success)

    },
    success(response) {
      if (!this.multiple) {
        this.$emit('fileUploaded', response.data)
      }
      if (this.clearAfterUpload) {
        this.mutablePreviewImage = []
      }
    },
  },
}
</script>
